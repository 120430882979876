<section  class="notificationBlock" [ngClass]="notShow ? 'show' : ''">
  <div class="container mt-5">
    <div class="row">

      <div class="col-sm-12" *ngIf="!notError">
        <div class="alert fade alert-simple alert-success alert-dismissible text-left font__family-montserrat font__size-16 font__weight-light brk-library-rendered rendered show">
          <button type="button" class="close font__size-18" (click)="!notShow">
									<span aria-hidden="true">
                    <i class="fa fa-times greencross"></i>
                  </span>
            <span class="sr-only">Close</span>
          </button>
          <i class="start-icon far fa-check-circle faa-tada animated"></i>
          <strong class="font__weight-semibold">Well done!</strong> {{notMessage}}
        </div>
      </div>

      <div class="col-sm-12" *ngIf="notError">
        <div class="alert fade alert-simple alert-danger alert-dismissible text-left font__family-montserrat font__size-16 font__weight-light brk-library-rendered rendered show" role="alert" data-brk-library="component__alert">
          <button type="button" class="close font__size-18" (click)="!notShow">
									<span aria-hidden="true">
										<i class="fa fa-times danger"></i>
									</span>
            <span class="sr-only">Close</span>
          </button>
          <i class="start-icon far fa-times-circle faa-pulse animated"></i>
          <strong class="font__weight-semibold">Oh snap!</strong> {{notMessage}}
        </div>
      </div>

    </div>
  </div>
</section>
